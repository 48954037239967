var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[(
      _vm.$route.path === '/videoSafe/video' ||
      _vm.$route.path === '/videoSafe/brand' ||
      _vm.$route.path === '/videoSafe/account' ||
      _vm.$route.path === '/videoSafe/trace' ||
      _vm.$route.path === '/videoSafe/copyright'
    )?_c('div',[(_vm.status == 2)?_c('div',{staticClass:"hearder"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('div',{staticClass:"right_font1"},[_vm._v("VideoSafe 数字版权保护系统")]),_c('div',{staticClass:"right_font2"},[_vm._v(" 三项专利技术加持，集合视频保护、品牌保护、账号保护等3项技术… ")]),_c('div',{staticClass:"right_button"},[_c('el-button',{staticStyle:{"width":"112px"},attrs:{"type":"primary","size":"medium"},on:{"click":_vm.kaitong}},[_vm._v(" 申请试用 ")]),_vm._m(1)],1)])]):_vm._e(),_c('el-tabs',{staticClass:"el-tabs",attrs:{"before-leave":_vm.leaveTab},model:{value:(_vm.path),callback:function ($$v) {_vm.path=$$v},expression:"path"}},_vm._l((_vm.tabItems),function(item){return _c('el-tab-pane',{key:item.name,attrs:{"label":item.name,"name":item.path}})}),1),(_vm.status2)?_c('openingfunction',{attrs:{"dialogstatus":_vm.status2},on:{"update:dialogstatus":function($event){_vm.status2=$event}}}):_vm._e()],1):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":require("../../assets/img/1.3.8/icon_bqbhtb.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_vm._v(" ￥1999 "),_c('span',{staticClass:"red",staticStyle:{"font-size":"14px","marign-left":"5px","font-weight":"bold"}},[_vm._v(" /年 ")])])
}]

export { render, staticRenderFns }