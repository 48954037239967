<template>
  <div class="contain">
    <div
      v-if="
        $route.path === '/videoSafe/video' ||
        $route.path === '/videoSafe/brand' ||
        $route.path === '/videoSafe/account' ||
        $route.path === '/videoSafe/trace' ||
        $route.path === '/videoSafe/copyright'
      "
    >
      <div class="hearder" v-if="status == 2">
        <div class="tu">
          <img src="../../assets/img/1.3.8/icon_bqbhtb.png" alt="" />
        </div>
        <!-- <div class="tu2" v--+
        
        +
        if="list.status == 2">
          <img :src="list.src" alt="" />
        </div>

        <div class="tu3" v-if="list.status == 3">
          <img :src="list.src" alt="" />
        </div> -->
        <div class="right">
          <div class="right_font1">VideoSafe 数字版权保护系统</div>
          <div class="right_font2">
            三项专利技术加持，集合视频保护、品牌保护、账号保护等3项技术…
          </div>
          <div class="right_button">
            <el-button
              type="primary"
              size="medium"
              @click="kaitong"
              style="width: 112px"
            >
              申请试用
            </el-button>
            <div class="price">
              ￥1999
              <span
                class="red"
                style="font-size: 14px; marign-left: 5px; font-weight: bold"
              >
                /年
              </span>
            </div>
            <!-- <div class="strick" v-if="list.strike">￥{{ list.strike }}</div> -->
          </div>
        </div>
      </div>
      <el-tabs :before-leave="leaveTab" class="el-tabs" v-model="path">
        <el-tab-pane
          v-for="item in tabItems"
          :key="item.name"
          :label="item.name"
          :name="item.path"
        ></el-tab-pane>
      </el-tabs>

      <openingfunction
        v-if="status2"
        :dialogstatus.sync="status2"
      ></openingfunction>
    </div>
    <router-view />
  </div>
</template>
<script>
import bg from '@/mixin/background'
import openingfunction from '@/components/copyrightprotect/openingfunction'

export default {
  name: 'copyright',

  mixins: [bg],

  data() {
    return {
      status2: false,
      path: '',
      status: this.$store.state.userInfo.jg_is_drm,
    }
  },
  components: {
    openingfunction,
  },

  computed: {
    tabItems() {
      return [
        {
          name: '视频保护',
          path: '/videoSafe/video',
          show: this.$store.state.roots.includes(159),
        },
        {
          name: '品牌保护',
          path: '/videoSafe/brand',
          show: this.$store.state.roots.includes(160),
        },
        {
          name: '账号保护',
          path: '/videoSafe/account',
          show: this.$store.state.roots.includes(81),
        },
        {
          name: '追溯维权',
          path: '/videoSafe/trace',
          show: this.$store.state.roots.includes(82),
        },
        {
          name: '版权登记',
          path: '/videoSafe/copyright',
          show: this.$store.state.roots.includes(83),
        },
      ].filter(item => item.show)
    },
  },

  methods: {
    leaveTab(activeName) {
      if (
        this.$store.getters.allMenuList.some(
          (item) => item.status == 2 && item.path == activeName
        )
      ) {
        this.$root.toggleProductUpdate()
        return false
      }
    },

    kaitong() {
      this.status2 = true
    },
  },
  watch: {
    path(path) {
      //判断端口中传过来的path是否在tabItems中存在
      if (path && this.tabItems.some((item) => item.path == path)) {
        // 跳转路由
        this.$router.push({ path })
      }
    },
    $route: {
      handler(value) {
        this.path = value.path
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.contain {
  margin: 20px;
}

.hearder {
  background-color: #fff;
  padding: 20px;
  display: flex;
  .tu,
  .tu2,
  .tu3 {
    width: 131px;
    height: 131px;
    background: #3299ff;
    border-radius: 20px;
    img {
      margin: 16px auto;
    }
  }
  .tu2 {
    background: #ff6c32;
    img {
      margin-top: 26px;
    }
  }
  .tu3 {
    background-color: #30b031;
    img {
      margin-top: 26px;
    }
  }

  .right {
    margin-left: 30px;
    .right_font1 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .right_font2 {
      margin-top: 14px;
      margin-bottom: 50px;
      font-size: 13px;
      color: #808080;
      line-height: 20px;
    }
    .right_button {
      display: flex;
      align-items: flex-end;
      .price {
        font-size: 18px;
        font-weight: bold;
        color: #ff3535;
        margin-left: 24px;
        margin-right: 12px;
      }
      .strick {
        font-size: 13px;
        text-decoration: line-through;
        color: #808080;
        line-height: 13px;
      }
    }
  }
}
.el-tabs {
  margin-top: 20px;
  padding-left: 20px;
  background: #fff;
}
::v-deep .el-tabs .el-tabs__nav-wrap::after {
  background: transparent;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.04);
}
::v-deep .el-tabs__header {
  margin: 0;
}
</style>
